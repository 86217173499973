import './style.css';

import L from 'leaflet';

var map = L.map('map').setView([25.0, -30.0], 5);

L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
}).addTo(map);

L.control.scale().addTo(map);

function get(url, callback) {
  var req = new XMLHttpRequest();
  req.onload = function() {
    callback(JSON.parse(req.responseText));
  };
  req.open('GET', url);
  req.send();
}

get('/api/track', function(data) {
    var polyline = L.polyline(data, { color: 'blue' }).addTo(map);
    var lastPoint = data[data.length-1];
    L.circleMarker(lastPoint, { fill: false, color: 'red', radius: 5 }).addTo(map);

    map.fitBounds(polyline.getBounds(), {maxZoom: 5});
});
